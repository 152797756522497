<template>
  <div class="upsel-card-container">
    <v-row>
      <v-col class="pt-4 pb-1">
        <UpsellCard :isCms="isCms" :data="heroData" :descriptionLength="1000"></UpsellCard>
      </v-col>
    </v-row>
    <v-row class="pr-2">
      <v-col
        md="4"
        lg="4"
        :xl="isCms ? 3: 4"
        xs="12"
        sm="12"
        class="pr-1"
        v-for="data in nonHeroData"
        :key="data.title"
      >
        <UpsellCard :isCms="isCms" :data="data" :descriptionLength="cardDescriptionLength"></UpsellCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UpsellCard from "./UpsellCard.vue";

export default {
  name: "UpsellCardsContainer",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isCms: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UpsellCard,
  },
  computed: {
    heroData() {
      return this.data.find((item) => item.is_hero) ?? this.data[0];
    },
    nonHeroData() {
      return this.data.filter((item) => !item.is_hero && !item?.is_hidden);
    },
    cardDescriptionLength() {
      return this.isCms ? 90 : 190;
    }
  },
};
</script>
<!-- <style lang="scss">
.upsel-card {
  .upsell-description {
    ul {
      column-count: 2;
      list-style: disc;
    }
  }
}
</style> -->
<style lang="scss" scoped></style>
