<template>
  <v-row class="ma-2">
    <v-card class="pa-5 d-flex align-center flex-wrap" width="100%">
      <v-row style="width:100%">
        <v-col
          :col="type == 'horizontal' ? '6' : '12'"
        >
          <v-skeleton-loader
            class="mb-1"
            elevation=0
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          v-if="type == 'horizontal'"
          col="6"
        >
          <v-skeleton-loader
            class="mb-1"
            elevation=0
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row 
          v-if="type != 'horizontal'"
           style="width:100%"
        >
        <v-col
          col="12"
        >
          <v-skeleton-loader
            class="mb-1"
            elevation=0
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>


<script>
export default {
  name: "UpsellSkeletonLoader",
  props: {
    type: {
      type: String,
      default: 'horizontal'
    }
  },
};
</script>
