<template>
  <hb-card
    class="upsell-card d-flex flex-wrap"
    :class="{ 'upsell-card-height': isCms && !data.is_hero, 'dashboard-card-height': !isCms && !data.is_hero}"
    width="100%"
    noTitle
  >
    <v-row>
      <v-col v-bind="attribs" class="pa-0 px-3">
        <div
          v-if="isCms && data.category"
          class="hb-font-header-3-medium primary--text"
        >
          {{ data.category }}
        </div>
        <div v-if="data.title" class="hb-font-header-2-medium mb-4" style="line-height: 1.1">
          {{ data.title }}
        </div>
        <div>

          <div v-if="!readMoreActivated && data.description.length > descriptionLength">
            <span
              class="cms-description"
              v-html="data.description.slice(0, descriptionLength) + '...'"
            ></span>
            <hb-link
              @click="activateReadMore"
              > Read More</hb-link
            >
          </div>
          
          <span
            v-if="data.description.length <= descriptionLength"
            v-html="data.description"
          ></span>
          <div v-if="readMoreActivated">
            <span class="cms-description" v-html="data.description"></span>
            <hb-link @click="activateReadMore">Read Less</hb-link>
          </div>
        </div>

        <div class="links-wrap d-flex flex-wrap align-center">
          <hb-btn
            @click="triggerAction()"
            class="mb-2 mt-2"
            color="primary"
            v-if="data.button && data.button.label"
          >
            {{ data.button.label }}
          </hb-btn>
          <hb-link
            v-if="data.learn_more && data.learn_more.url"
            :href="data.learn_more.url"
            target="_blank"
            class="mb-2"
          >
            {{ data.learn_more.label }}
          </hb-link>
        </div>
      </v-col>
      <v-col
        v-bind="attribs"
        v-if="data.is_hero"
        class="hero-image-wrap justify-center align-center d-none d-md-flex pa-0"
      >
        <template v-if="data.video && data.video.id">
          <img
            v-if="data.video.thumbnail"
            :src="data.video.thumbnail"
            :alt="data.title"
            @click="openVideo"
            class="video-trigger"
          />
          <img
            v-else
            src="../../../../assets/images/video-placeholder.png"
            :alt="data.title"
            @click="openVideo"
            class="video-trigger"
          />
          <div class="play-btn-wrap">
            <span class="play-icon"></span>
          </div>
          <modal-video
            :channel="videoChannel"
            :videoId="videoId"
            :isOpen.sync="videoIsOpen"
          />
        </template>
        <img v-else :src="data.image" :alt="data.title" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-bind="attribs"
        v-if="!data.is_hero"
        class="image-wrap justify-center align-center d-none d-md-flex pa-0 px-3 pt-3"
      >
        <template v-if="data.video && data.video.id">
          <img
            v-if="data.video.thumbnail"
            :src="data.video.thumbnail"
            :alt="data.title"
            @click="openVideo"
            class="video-trigger"
          />
          <img
            v-else
            src="../../../../assets/images/video-placeholder.png"
            :alt="data.title"
            @click="openVideo"
            class="video-trigger"
          />
          <div class="play-btn-wrap">
            <span class="play-icon"></span>
          </div>
          <modal-video
            :channel="videoChannel"
            :videoId="videoId"
            :isOpen.sync="videoIsOpen"
          />
        </template>
        <img v-else :src="data.image" :alt="data.title" />
      </v-col>
    </v-row>
  </hb-card>
</template>

<script>
import { EventBus } from "@/EventBus.js";
import ModalVideo from "@/components/dashboard/widgets/components/ModalVideo.vue";
export default {
  name: "UpsellCard",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    isCms: {
      type: Boolean,
      default: false,
    },
    descriptionLength: {
      type: Number,
      default: 90,
    }
  },
  components: {
    ModalVideo,
  },
  data() {
    return {
      videoIsOpen: false,
      readMoreActivated: false,
    };
  },
  computed: {
    attribs() {
      return {
        md: this.data?.is_hero ? "6" : "12",
        lg: this.data?.is_hero ? "6" : "12",
        xs: "12",
        sm: "12",
      };
    },
    videoChannel: function () {
      //Logic to extract based on URL
      return this.data.video?.type ?? "";
    },
    videoId: function () {
      //Logic to extract based on URL
      return this.data.video?.id ?? "";
    },
  },
  watch: {},
  methods: {
    triggerAction() {
      if (!this.isCms && this.data.is_hero)
        EventBus.$emit("HB-Navigation:showSettingsModal", "cms");
      else
        window.open(
          this.data.button.url ?? "https://www.tenantinc.com/#Get-Access",
          "_blank"
        );
    },
    activateReadMore() {
      this.readMoreActivated = !this.readMoreActivated;
    },

    openVideo() {
      this.videoIsOpen = !this.videoIsOpen;
    },
  },
};
</script>
<style lang="scss">
.upsell-card {
  .cms-description {
    p {
      margin-bottom: 0px !important;
      display: inline;
    }
  }
}
</style>
<style lang="scss" scoped>

@media (min-width: 1300px) {
  .upsell-card-height {
    min-height: 470px;
  }
}
@media (max-width: 1300px) {
  .upsell-card-height {
    min-height: 500px;
  }
}
@media (min-width: 1800px) {
  .upsell-card-height {
    min-height: 470px;
  }
}


@media (min-width: 1300px) {
  .dashboard-card-height {
    min-height: 480px;
  }
}
@media (max-width: 1300px) {
  .dashboard-card-height {
    min-height: 510px;
  }
}
@media (min-width: 1400px) {
  .dashboard-card-height {
    min-height: 530px;
  }
}

@media (min-width: 1600px) {
  .dashboard-card-height {
    min-height: 560px;
  }
}
@media (min-width: 1800px) {
  .dashboard-card-height {
    min-height: 550px;
  }
}

@media (min-width: 2000px) {
  .dashboard-card-height {
    min-height: 495px;
  }
}
.upsell-card {
  padding: 56px;
  .hero-image-wrap {
    position: relative;
    cursor: pointer;
    img {
      height: 100%;
      width: 55%;
      object-fit: contain;
    }
  }
  .image-wrap {
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .links-wrap {
    span {
      margin-right: 10px;
    }
  }
@media (max-width: 1400px) {
  .play-btn-wrap {
    height: 60px !important;
    width: 60px !important;
    .play-icon {
      height: 21px !important;
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;
      border-left: 15px solid white !important;
    }
  }

}
  .play-btn-wrap {
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: #141414bf;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    .play-icon {
      content: "";
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      width: 0;
      height: 41px;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 30px solid white;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
